import React, { useEffect, useState } from "react";
import "@shopify/polaris/build/esm/styles.css";
import { Provider } from "@shopify/app-bridge-react";

import axios from "axios";

import Configurations from "./Configurations";

function App() {
  const params = new URLSearchParams(window.location.search);
  const config = {
    apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
    host: params.get("host"),
    forceRedirect: true,
  };
  const [state, setState] = useState(false);

  const checkIfInstalled = () => {
    axios
      .get(`${process.env.REACT_APP_LIFEDNA_API}/shopify/initialize` + window.location.search)
      .then((res) => {
        if (res.data.url !== undefined) {
          window.location.href = res.data.url;
        } else {
          setState(true);
        }
      });
  };

  useEffect(() => {
    checkIfInstalled();
  }, []);

  return (
    <>
      {state ? (
        <Provider config={config}>
          <Configurations />
        </Provider>
      ) : null}
    </>
  );
}

export default App;
